import './src/styles/index.scss'

function addJS(jsCode) {
  const s = document.createElement(`script`)

  s.type = `text/javascript`
  s.innerText = jsCode
  document.getElementsByTagName(`head`)[0].appendChild(s)
}

const injectTwitterScript = () => {
  addJS(`
    window.twttr = (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);
      t._e = [];
      t.ready = function(f) {
        t._e.push(f);
      };
      return t;
    })(document, "script", "twitter-wjs");
  `)
}

const injectInstagramScript = function injectScript() {
  const js = document.createElement('script')
  const firstScript = document.getElementsByTagName('script')[0]
  js.id = 'gatsby-plugin-instagram'
  js.src = 'https://instagram.com/embed.js'
  firstScript.parentNode.insertBefore(js, firstScript)
  return true
}

const embedInstagramClasses = ['.instagram-media'].join(',')

let injectedTwitterScript = false
let injectedInstagramScript = false

const embedClasses = [
  `.twitter-tweet`,
  `.twitter-timeline`,
  `.twitter-follow-button`,
  `.twitter-share-button`,
].join(`,`)

export const onRouteUpdate = () => {
  if (document.querySelector(embedClasses) !== null) {
    if (!injectedTwitterScript) {
      injectTwitterScript()
      injectedTwitterScript = true
    }

    if (
      typeof twttr !== `undefined` &&
      window.twttr.widgets &&
      typeof window.twttr.widgets.load === `function`
    ) {
      setTimeout(() => {
        window.twttr.widgets.load()
      }, 0)
    }
  }

  if (document.querySelector(embedInstagramClasses) !== null) {
    if (!injectedInstagramScript) {
      injectInstagramScript()
      injectedInstagramScript = true
    }

    if (
      typeof instgrm !== 'undefined' &&
      window.instgrm.Embeds &&
      typeof window.instgrm.Embeds.process === 'function'
    ) {
      setTimeout(() => {
        window.instgrm.Embeds.process()
      }, 0)
    }
  }
}
